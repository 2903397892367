<template>
  <div>
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan vuokrasopimusta..."
      class="full-page-loader"
    ></full-page-loader>

    <!-- FORM -->
    <v-form v-if="!loading" ref="form">
      <v-card class="mt-2">
        <v-card-title>Vuokranantaja</v-card-title>

        <v-container>
          <div v-for="(landlord, index) in contract.landlords" :key="index + 'a'">
            <v-row
              dense
              :style="[
                editOldContractAfterSigning(false) || isDisabled('rentalContract', 'landlords')
                  ? { display: 'none' }
                  : { display: 'block' },
              ]"
            >
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <label
                  >Hae vuokranantaja
                  <small
                    class="ml-3 error--text"
                    v-if="index > 0"
                    @click="deleteLandlord(index)"
                    style="font-size: 16px; cursor: pointer"
                    >Poista</small
                  ></label
                >
                <v-autocomplete
                  :disabled="editOldContractAfterSigning(false)"
                  :value="landlord.landlordId"
                  :items="activeLandlords"
                  item-text="name"
                  item-value="_id"
                  no-data-text="Toimeksijantajan tai oman tilisi nimi"
                  outlined
                  dense
                  small-chips
                  return-object
                  hide-details
                  :rules="validations.required"
                  @change="updateLandlord($event, { ...landlord }, index)"
                  @input.native="getLandlords"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-radio-group v-model="landlord.isCompany" :mandatory="true" disabled hide-details>
                  <v-radio label="Yksityinen" :value="false"></v-radio>
                  <v-radio label="Yritys" :value="true"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="pr-2" cols="12" xl="2" lg="3" md="4" sm="6">
                <label>Nimi</label>
                <v-text-field
                  v-model="landlord.name"
                  :rules="validations.required"
                  disabled
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col v-if="!landlord.isCompany" class="pr-2" cols="12" xl="2" lg="3" md="4" sm="6">
                <label>Henkilötunnus</label>
                <v-text-field
                  v-model="landlord.social"
                  outlined
                  dense
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col v-if="landlord.isCompany" class="pr-2" cols="12" xl="2" lg="3" md="4" sm="6">
                <label>Y-tunnus</label>
                <v-text-field
                  v-model="landlord.businessId"
                  outlined
                  dense
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col class="pr-2" cols="12" xl="2" lg="3" md="4" sm="6">
                <label>Osoite</label>
                <v-text-field
                  v-model="landlord.address"
                  outlined
                  dense
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col class="pr-2" cols="12" xl="2" lg="3" md="4" sm="6">
                <label>Postinumero</label>
                <v-text-field
                  v-model="landlord.zipCode"
                  outlined
                  dense
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col class="pr-2" cols="12" xl="2" lg="3" md="4" sm="6">
                <label>Kaupunki</label>
                <v-text-field
                  v-model="landlord.city"
                  outlined
                  dense
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col class="pr-2" cols="12" xl="2" lg="3" md="4" sm="6">
                <label>Puhelin</label>
                <v-text-field
                  v-model="landlord.phone"
                  outlined
                  dense
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col class="pr-2" cols="12" xl="2" lg="3" md="4" sm="6">
                <label>Email</label>
                <v-text-field
                  v-model="landlord.email"
                  :rules="validations.email2"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-2 mx-3" v-if="index < contract.landlords.length - 1"></v-divider>
          </div>

          <v-row
            :style="[
              editOldContractAfterSigning(false) || isDisabled('rentalContract', 'landlords')
                ? { display: 'none' }
                : { display: 'block' },
            ]"
            dense
          >
            <v-col cols="12" md="4" xl="3">
              <v-btn color="primary" @click="addLandlord">Lisää vuokranantaja</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Vuokralainen</v-card-title>

        <v-container>
          <v-row
            :style="[
              editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                ? { display: 'none' }
                : { display: 'block' },
            ]"
            dense
          >
            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <!-- Create new tenant -->
              <v-btn
                :disabled="editOldContractAfterSigning(false)"
                color="info"
                small
                @click="openTenantDialog"
                >Luo uusi vuokralainen</v-btn
              >
            </v-col>

            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <label>Hae vuokralainen</label>

              <v-autocomplete
                :value="contract.tenant.tenantId"
                :items="activeTenants"
                item-text="name"
                item-value="_id"
                no-data-text="Vuokralaisen nimi"
                outlined
                dense
                small-chips
                hide-details
                return-object
                @change="setTenant($event)"
                :disabled="editOldContractAfterSigning(false)"
                @input.native="getTenants"
                :rules="validations.required"
              >
              </v-autocomplete>
              <small
                class="error--text"
                style="cursor: pointer; margin-top: 5px"
                @click="
                  contract.tenant = {};
                  saveTenantInformation = false;
                "
                :style="[
                  editOldContractAfterSigning(false) ? { display: 'none' } : { display: 'inline' },
                ]"
                >Poista vuokralainen</small
              >
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6" md="4" sm="6" xl="3">
              <v-radio-group
                v-model="contract.tenant.isCompany"
                :mandatory="true"
                :disabled="
                  editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                "
                hide-details
              >
                <v-radio label="Yksityinen" :value="false"></v-radio>
                <v-radio label="Yritys" :value="true"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col
              v-if="!contract.tenant.isCompany"
              title="Laskutusohjelmat soveltavat erilaisia käytäntöjä saatavien perintään kansalaisuuteen perustuen."
              cols="6"
              md="4"
            >
              <v-checkbox
                v-model="contract.tenant.finnishCitizen"
                :label="`Vuokralaisella on suomalainen henkilötunnus`"
                :disabled="
                  editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                "
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <label>Nimi</label>
              <v-text-field
                v-model="contract.tenant.name"
                :rules="validations.required"
                outlined
                dense
                :disabled="
                  editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                "
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <div v-if="contract.tenant.isCompany">
                <label>Y-tunnus</label>
                <v-text-field
                  v-model="contract.tenant.businessId"
                  outlined
                  dense
                  :disabled="
                    editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                  "
                  hide-details
                ></v-text-field>
              </div>
              <div v-if="!contract.tenant.isCompany">
                <label>Henkilötunnus</label>
                <v-text-field
                  v-model="contract.tenant.social"
                  :rules="socialRules"
                  outlined
                  dense
                  :disabled="
                    editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                  "
                  hide-details
                ></v-text-field>
              </div>
            </v-col>

            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <label>Email</label>
              <v-text-field
                v-model="contract.tenant.email"
                :rules="validations.email2"
                outlined
                dense
                :disabled="
                  editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                "
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <label>Osoite</label>
              <v-text-field
                v-model="contract.tenant.address"
                outlined
                dense
                :disabled="
                  editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                "
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <label>Postinumero</label>
              <v-text-field
                v-model="contract.tenant.zipCode"
                outlined
                dense
                :disabled="
                  editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                "
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <label>Kaupunki</label>
              <v-text-field
                v-model="contract.tenant.city"
                outlined
                dense
                :disabled="
                  editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                "
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <label>Puhelin</label>
              <v-text-field
                v-model="contract.tenant.phone"
                outlined
                dense
                :disabled="
                  editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                "
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <!-- Update tenant information -->
              <v-checkbox
                :style="[
                  editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                    ? { display: 'none' }
                    : { display: 'block' },
                ]"
                v-model="saveTenantInformation"
                label="Tallenna päävuokralaiselle muuttuneet tiedot"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <!-- Other tenants-->
          <h3 v-if="contract.otherTenants.length > 0" class="mb-2 mt-2">Muut vuokralaiset</h3>
          <div v-for="(tenant, index) in contract.otherTenants" :key="index + 'b'">
            <v-row
              :style="[
                editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                  ? { display: 'none' }
                  : { display: 'block' },
              ]"
              dense
            >
              <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
                <label
                  >Hae vuokralainen
                  <span
                    class="ml-3 error--text"
                    @click="deleteOtherTenant(index)"
                    style="font-size: 16px; cursor: pointer"
                    >Poista</span
                  ></label
                >

                <v-autocomplete
                  :value="tenant.tenantId"
                  :items="activeTenants"
                  item-text="name"
                  item-value="_id"
                  no-data-text="Vuokralaisen nimi"
                  outlined
                  dense
                  small-chips
                  hide-details
                  return-object
                  @change="updateTenant($event, { ...tenant }, index)"
                  :disabled="editOldContractAfterSigning(false)"
                  @input.native="getTenants"
                  :rules="validations.required"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="pr-2" cols="12" sm="6" md="4" lg="3" xl="2">
                <label>Nimi</label>
                <v-text-field
                  v-model="tenant.name"
                  outlined
                  dense
                  disabled
                  :rules="validations.required"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col v-if="!tenant.isCompany" class="pr-2" cols="12" sm="6" md="4" lg="3" xl="2">
                <label>Henkilötunnus</label>

                <v-text-field
                  v-model="tenant.social"
                  outlined
                  dense
                  :disabled="
                    editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                  "
                  hide-details
                />
              </v-col>

              <v-col v-if="tenant.isCompany" class="pr-2" cols="12" sm="6" md="4" lg="3" xl="2">
                <label>Y-tunnus</label>
                <v-text-field
                  v-model="tenant.businessId"
                  outlined
                  dense
                  :disabled="
                    editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                  "
                  hide-details
                />
              </v-col>

              <v-col class="pr-2" cols="12" sm="6" md="4" lg="3" xl="2">
                <label>Email</label>
                <v-text-field
                  v-model="tenant.email"
                  outlined
                  dense
                  :rules="validations.email2"
                  :disabled="
                    editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                  "
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-3 mx-2"></v-divider>
          </div>

          <v-row
            class="mt-3"
            :style="[
              editOldContractAfterSigning(false) || isDisabled('rentalContract', 'tenant')
                ? { display: 'none' }
                : { display: 'block' },
            ]"
            dense
          >
            <v-col cols="12" md="4">
              <v-btn color="primary" @click="addTenant">Lisää vuokralainen</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Vuokrakohde</v-card-title>

        <v-container>
          <v-row
            :style="[
              editOldContractAfterSigning(false) || isDisabled('rentalContract', 'apartment')
                ? { display: 'none' }
                : { display: 'block' },
            ]"
            dense
          >
            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <label>Hae vuokrakohde</label>
              <h5
                v-if="
                  Object.keys(contract.apartment).length > 0 && !contract.apartment.activeForRent
                "
                class="error--text mt-1"
              >
                Vuokrakohde ei ole vuokrattavana
              </h5>

              <v-autocomplete
                :value="contract.apartment.id"
                :items="activeApartments"
                :item-text="fullAddress"
                item-value="_id"
                no-data-text="Vuokrakohteen osoite"
                outlined
                dense
                small-chips
                hide-details
                return-object
                @change="setApartment($event)"
                :disabled="editOldContractAfterSigning(false)"
                @input.native="getApartments"
                :rules="validations.required"
              >
              </v-autocomplete>

              <small class="error--text" style="cursor: pointer" @click="contract.apartment = {}"
                >Poista vuokrakohde</small
              >
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="pr-2" cols="12" md="4" sm="6" lg="3" xl="2">
              <label>Huoneet</label>
              <v-autocomplete
                v-model="contract.apartment.roomIds"
                :items="activeRooms"
                item-text="name"
                item-value="_id"
                no-data-text="Ei huoneita"
                outlined
                dense
                small-chips
                hide-details
                multiple
                deletable-chips
                :disabled="editOldContractAfterSigning(false)"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="pr-2" cols="12" sm="6" md="4" lg="3" xl="2">
              <label>Osoite</label>
              <v-text-field
                v-model="contract.apartment.address"
                :rules="validations.required"
                disabled
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col
              v-if="contract.apartment.apartmentNumber"
              class="pr-2"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <label>Vuokrakohteen numero</label>
              <v-text-field
                v-model="contract.apartment.apartmentNumber"
                :rules="validations.required"
                disabled
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="pr-2" cols="12" sm="6" md="4" lg="3" xl="2">
              <label>Postinumero</label>
              <v-text-field
                v-model="contract.apartment.zipCode"
                :rules="validations.required"
                disabled
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="pr-2" cols="12" sm="6" md="4" lg="3" xl="2">
              <label>Kaupunki</label>
              <v-text-field
                v-model="contract.apartment.city"
                :rules="validations.required"
                disabled
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="pr-2" cols="12" sm="6" md="4" lg="3" xl="2">
              <label>Maa</label>
              <v-text-field
                v-model="contract.apartment.country"
                disabled
                outlined
                dense
                hide-details
                :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Vuokra-aika</v-card-title>
        <v-container>
          <v-row dense>
            <v-col class="pr-2" cols="12" sm="6" md="4" lg="3" xl="2">
              <label>Sopimustyyppi</label>
              <v-radio-group
                v-model="contract.lease.leaseType"
                :mandatory="true"
                @change="
                  contract.lease.leaseType == 'Toistaiseksi voimassa oleva'
                    ? (contract.lease.endDate = '')
                    : contract.lease.endDate
                "
                :disabled="
                  editOldContractAfterSigning(false) ||
                  isDisabled('rentalContract', 'lease.leaseType')
                "
              >
                <v-radio
                  label="Toistaiseksi voimassa oleva"
                  value="Toistaiseksi voimassa oleva"
                ></v-radio>
                <v-radio label="Määräaikainen" value="Määräaikainen"></v-radio>
              </v-radio-group>

              <div class="mb-1">
                <label>Vuokrasopimuksen alkamispäivä</label>
                <v-menu
                  ref="startMenu"
                  v-model="startMenu"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedStart"
                      v-bind="attrs"
                      :rules="validations.required"
                      v-on="on"
                      outlined
                      dense
                      append-icon="mdi-calendar"
                      :disabled="
                        editOldContractAfterSigning(false) ||
                        isDisabled('rentalContract', 'lease.startDate')
                      "
                      v-prevent-manual-input
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="contract.lease.startDate"
                    first-day-of-week="1"
                    @input="startMenu = false"
                  ></v-date-picker>
                </v-menu>

                <div v-if="contract.lease.leaseType == 'Määräaikainen'">
                  <label>Vuokrasopimuksen päättymispäivä</label>
                  <v-menu
                    ref="endMenu"
                    v-model="endMenu"
                    :close-on-content-click="false"
                    min-width="290"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormattedEnd"
                        outlined
                        dense
                        v-bind="attrs"
                        :rules="validations.required"
                        v-on="on"
                        append-icon="mdi-calendar"
                        :disabled="
                          editOldContractAfterSigning(false) ||
                          isDisabled('rentalContract', 'lease.endDate')
                        "
                        v-prevent-manual-input
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="contract.lease.endDate"
                      first-day-of-week="1"
                      @input="endMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>

              <label>Ensimmäinen irtisanomispäivä</label>
              <v-menu
                ref="dismissalMenu"
                v-model="dismissalMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(contract.lease.dismissalDate)"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                    :disabled="
                      editOldContractAfterSigning(false) ||
                      isDisabled('rentalContract', 'lease.dismissalDate')
                    "
                    hide-details
                    clearable
                    @click:clear="contract.lease.dismissalDate = ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="contract.lease.dismissalDate"
                  first-day-of-week="1"
                  @input="dismissalMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Vuokran vuosittainen korotus</v-card-title>
        <v-container>
          <v-checkbox
            v-model="contract.lease.noRentIncrease"
            label="Vuokraa ei koroteta"
            :success="editOldContractAfterSigning(false)"
          ></v-checkbox>

          <div v-if="!contract.lease.noRentIncrease">
            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <label>Seuraava vuokrankorotuksen pvm.</label>
                <v-menu
                  ref="rentIncreaseMenu"
                  v-model="rentIncreaseMenu"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedRentIncrease"
                      v-bind="attrs"
                      :rules="validations.required"
                      v-on="on"
                      outlined
                      dense
                      append-icon="mdi-calendar"
                      :success="editOldContractAfterSigning(false)"
                      v-prevent-manual-input
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="contract.lease.nextRentIncreaseDate"
                    first-day-of-week="1"
                    @input="rentIncreaseMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3">
                <label>Korotuksen peruste</label>
                <v-select
                  class="mb-1"
                  v-model="contract.lease.rentIncreaseType"
                  :items="globalValues.rentIncreaseValues"
                  dense
                  outlined
                  item-text="text"
                  item-value="val"
                  :rules="validations.required"
                  hide-details
                  :success="editOldContractAfterSigning(false)"
                ></v-select>

                <div
                  v-if="
                    contract.lease.rentIncreaseType == 'index' ||
                    contract.lease.rentIncreaseType == 'indexPlusPercentage' ||
                    contract.lease.rentIncreaseType == 'consumerIndex' ||
                    contract.lease.rentIncreaseType == 'consumerIndexPlusPercentage' ||
                    contract.lease.rentIncreaseType == 'propertyMaintenanceIndex' ||
                    contract.lease.rentIncreaseType == 'propertyMaintenancePlusPercentageIndex'
                  "
                >
                  <v-row dense>
                    <v-checkbox
                      v-if="
                        contract.lease.rentIncreaseType == 'propertyMaintenanceIndex' ||
                        contract.lease.rentIncreaseType == 'propertyMaintenancePlusPercentageIndex'
                      "
                      v-model="contract.lease.compareToPreviousIndex"
                      outlined
                      dense
                      hide-details
                      label="Vertaile edelliseen indeksin pistelukuun (oletuksena alkuperäiseen)"
                      :success="editOldContractAfterSigning(false)"
                    ></v-checkbox>

                    <v-col>
                      <v-btn color="primary" @click="getLatestIndex">Hae viimeisin pisteluku</v-btn>
                    </v-col>
                  </v-row>

                  <label>Indeksin kk/vuosi</label>
                  <v-menu
                    ref="indexMenu"
                    v-model="indexMenu"
                    :close-on-content-click="false"
                    min-width="290"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        class="mb-1"
                        v-model="dateFormattedIndexStartDate"
                        v-bind="attrs"
                        :rules="validations.required"
                        v-on="on"
                        outlined
                        dense
                        append-icon="mdi-calendar"
                        :success="editOldContractAfterSigning(false)"
                        v-prevent-manual-input
                        v-prevent-paste
                        :loading="loadingIndex"
                        hide-details
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="contract.lease.indexStartDate"
                      first-day-of-week="1"
                      type="month"
                      @input="indexMenu = false"
                      @change="getIndex"
                      :allowed-dates="allowedIndexDates"
                    ></v-date-picker>
                  </v-menu>

                  <label>Indeksipisteluku</label>
                  <v-text-field
                    class="mb-1"
                    v-model.number="contract.lease.indexStartValue"
                    type="number"
                    step="0.01"
                    :rules="validations.required"
                    outlined
                    dense
                    :success="editOldContractAfterSigning(false)"
                    :loading="loadingIndex"
                    hide-details
                  >
                  </v-text-field>

                  <label>Alkuperäinen vuokra</label>
                  <v-text-field
                    class="mb-1"
                    v-model.number="contract.lease.startRentPrice"
                    outlined
                    dense
                    type="number"
                    step="1"
                    suffix="€"
                    :success="editOldContractAfterSigning(false)"
                    :rules="validations.required"
                    hide-details
                  >
                  </v-text-field>
                </div>

                <div
                  class="mt-1"
                  v-if="
                    contract.lease.rentIncreaseType == 'percentage' ||
                    contract.lease.rentIncreaseType == 'indexPlusPercentage' ||
                    contract.lease.rentIncreaseType == 'consumerIndexPlusPercentage' ||
                    contract.lease.rentIncreaseType == 'propertyMaintenancePlusPercentageIndex'
                  "
                >
                  <label>Prosenttia</label>
                  <v-text-field
                    class="mb-1"
                    v-model.number="contract.lease.rentIncreasePercentage"
                    type="number"
                    step="0.01"
                    :rules="validations.required"
                    outlined
                    dense
                    suffix="%"
                    :success="editOldContractAfterSigning(false)"
                    hide-details
                  ></v-text-field>

                  <label>Prosenttikorotuksen tarkennus</label>
                  <v-select
                    class="mb-1"
                    v-model="contract.lease.percentagePrefix"
                    :items="['enintään', 'vähintään']"
                    outlined
                    dense
                    :success="editOldContractAfterSigning(false)"
                    clearable
                    hide-details
                  ></v-select>
                </div>

                <div class="mt-1 mb-1" v-if="contract.lease.rentIncreaseType == 'fixed'">
                  <label>Euroa (€)</label>
                  <v-text-field
                    v-model.number="contract.lease.rentIncreaseFixed"
                    type="number"
                    step="0.01"
                    :rules="validations.required"
                    outlined
                    dense
                    :success="editOldContractAfterSigning(false)"
                    hide-details
                  ></v-text-field>
                </div>

                <div class="mb-1" v-if="contract.lease.rentIncreaseType == 'own'">
                  <p class="error--text" style="font-size: 11px !important; margin-bottom: 15px">
                    Automaattiset vuokrankorotukset eivät toimi tällä vaihtoehdolla
                  </p>
                  <label>Tarkenne</label>
                  <v-text-field
                    v-model="contract.lease.rentIncreaseOwn"
                    :rules="validations.required"
                    outlined
                    dense
                    :success="editOldContractAfterSigning(false)"
                    hide-details
                  ></v-text-field>
                </div>

                <label>Kommentit</label>
                <v-text-field
                  v-model="contract.lease.rentIncreaseComment"
                  outlined
                  dense
                  persistent-hint
                  hint="Näytetään vuokrankorotusta tehdessä"
                  :success="editOldContractAfterSigning(false)"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <v-btn color="info" class="mr-4 mt-5" @click="sendContract">{{ btnText }}</v-btn>
        </v-container>
      </v-card>
    </v-form>

    <!-- Create tenant dialog -->
    <create-tenant-dialog v-model="newTenantDialog" />
  </div>
</template>

<script>
import mixins from "../../../mixins/mixins";
import invoiceMixins from "../../../mixins/invoiceMixins";
import { mapActions, mapState, mapMutations } from "vuex";
import { debounce } from "../../../utils/helpers";
import _ from "lodash";
import FullPageLoader from "@/components/FullPageLoader.vue";
import CreateTenantDialog from "@/components/Tenant/CreateTenantDialog";
import globalValues from "@/configs/globalValues";
import validations from "@/validations";
import moment from "moment";

export default {
  mixins: [mixins, invoiceMixins],

  components: {
    FullPageLoader,
    CreateTenantDialog,
  },

  props: {
    btnText: { type: String, default: "" },
    contr: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data(vm) {
    return {
      rentIncreaseMenu: false,
      startMenu: false,
      dismissalMenu: false,
      endMenu: false,
      indexMenu: false,
      dateFormattedStart: "",
      dateFormattedEnd: "",
      dateFormattedRentIncrease: "",
      dateFormattedIndexStartDate: "",
      searchInput: "",
      saveTenantInformation: false,
      landlordInput: "",
      tenantInput: "",
      apartmentInput: "",
      newTenantDialog: false,
      contract: {
        landlords: [
          {
            isCompany: false,
            name: "",
            social: "",
            businessId: "",
            address: "",
            zipCode: "",
            city: "",
            phone: "",
            bank: "",
            iban: "",
            email: "",
            onModel: "",
            landlordId: "",
          },
        ],
        landlord: { isCompany: false },
        tenant: { isCompany: false, finnishCitizen: false },
        otherTenants: [],
        apartment: { activeForRent: true, roomIds: [] },
        lease: {
          noRentIncrease: false,
          leaseType: "",
          startDate: "",
          endDate: "",
          dismissalDate: "",
          rentIncreaseType: "percentage",
          rentIncreasePercentage: null,
          percentagePrefix: "enintään",
          rentIncreaseFixed: null,
          rentIncreaseOwn: "",
          nextRentIncreaseDate: "",
          indexStartDate: "",
          indexStartValue: null,
          startRentPrice: null,
          rentIncreaseComment: "",
          compareToPreviousIndex: false,
        },
      },
      socialRules: [(v) => vm.checkFinnishCitizen(v)],
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("tenant", ["activeTenants"]),
    ...mapState("account", ["activeLandlords"]),
    ...mapState("apartment", ["activeApartments"]),
    ...mapState("contract", ["loading"]),
    ...mapState("rentIncrease", ["indexValue", "indexDate", "loadingIndex"]),

    showOnlyOnCreate() {
      return this.$route.name == "Luo voimassa oleva vuokrasopimus" ? true : false;
    },

    activeRooms() {
      if (this.contract?.apartment?.rooms && this.contract.apartment.rooms.length > 0) {
        return this.contract.apartment.rooms.filter((el) => el.active);
      } else {
        return [];
      }
    },
  },

  watch: {
    async contr(val) {
      try {
        delete val._id;
        delete val.id;
        delete val.createdBy;
        delete val.updatedAt;
        delete val.createdAt;
        delete val.__v;
        let contract = _.cloneDeep(val);

        // // Set initial values to arrays
        const landlordIds = contract.landlords.map((el) => el.landlordId);
        const tenantIds = [
          contract.tenant.tenantId._id,
          ...contract.otherTenants.map((el) => el.tenantId),
        ];
        const apartmentIds = [contract.apartment.id._id];

        await Promise.all([
          this.getLandlordsById(landlordIds),
          this.getTenantsById(tenantIds),
          this.getApartmentsById(apartmentIds),
        ]);

        // Set tenantId and apartment.id to _id value. its populated when it arrives
        contract.tenant.tenantId = val.tenant.tenantId._id;
        // Set apartment
        contract.apartment.activeForRent = val.apartment.id.activeForRent;
        contract.apartment.id = val.apartment.id._id;
        contract.apartment.rooms = val.apartment.id.rooms;

        this.contract = contract;

        this.setLoading(false);
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    indexValue(val) {
      this.contract.lease.indexStartValue = val;
    },

    indexDate(val) {
      this.contract.lease.indexStartDate = val;
    },

    "contract.lease.startDate"() {
      this.dateFormattedStart = this.formatDate(this.contract.lease.startDate);
    },

    "contract.lease.endDate"() {
      this.dateFormattedEnd = this.formatDate(this.contract.lease.endDate);
    },

    "contract.lease.nextRentIncreaseDate"() {
      this.dateFormattedRentIncrease = this.formatDate(this.contract.lease.nextRentIncreaseDate);
    },

    "contract.lease.indexStartDate"(val) {
      this.dateFormattedIndexStartDate = this.formatDateMonthName(val);
    },

    "contract.lease.rentIncreaseType"(val) {
      if (
        val &&
        val != "propertyMaintenanceIndex" &&
        val != "propertyMaintenancePlusPercentageIndex"
      ) {
        this.contract.lease.compareToPreviousIndex = false;
      }
    },

    landlordInput: debounce(function (newVal) {
      this.searchAllLandlords(`/api/v1/account/search-landlords?search=${newVal}`);
    }, 1000),

    tenantInput: debounce(function (newVal) {
      this.searchTenant(`/api/v1/tenant/search-tenant?search=${newVal}`);
    }, 1000),

    apartmentInput: debounce(function (newVal) {
      this.searchApartment(`/api/v1/apartment/search-apartment?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("tenant", ["searchTenant", "getTenantsById"]),
    ...mapActions("account", ["searchAllLandlords", "getLandlordsById"]),
    ...mapActions("apartment", ["searchApartment", "getApartmentsById"]),
    ...mapActions("rentIncrease", ["getIndexValue", "getLatestIndexValue"]),
    ...mapMutations("contract", ["setLoading"]),
    ...mapMutations("rentIncrease", ["setLoadingIndex"]),
    ...mapMutations("tenant", ["setDefaultTenant"]),

    getTenants(event) {
      this.tenantInput = event.target.value;
    },

    getLandlords(event) {
      this.landlordInput = event.target.value;
    },

    getApartments(event) {
      this.apartmentInput = event.target.value;
    },

    fullAddress(item) {
      return item.apartmentNumber ? item.address + ", " + item.apartmentNumber : item.address;
    },

    openTenantDialog() {
      this.setDefaultTenant(this.$store.state.account);
      this.newTenantDialog = true;
    },

    addLandlord() {
      this.contract.landlords.push({
        isCompany: false,
        name: "",
        social: "",
        businessId: "",
        address: "",
        zipCode: "",
        city: "",
        phone: "",
        bank: "",
        iban: "",
        email: "",
        onModel: "",
        landlordId: "",
      });
    },

    addTenant() {
      this.contract.otherTenants.push({
        name: "",
        isCompany: false,
        social: "",
        businessId: "",
        email: "",
        tenantId: null,
      });
    },

    deleteLandlord(index) {
      this.contract.landlords.splice(index, 1);
    },

    deleteOtherTenant(index) {
      this.contract.otherTenants.splice(index, 1);
    },

    updateLandlord(event, landlord, index) {
      if (event) {
        landlord.name = event.name;
        landlord.address = event.address;
        landlord.city = event.city;
        landlord.zipCode = event.zipCode;
        landlord.email = event.email;
        landlord.phone = event.phone;
        landlord.bank = event.bank;
        landlord.iban = event.iban;
        landlord.landlordId = event._id;
        delete landlord._id;

        if (event.isCompany) {
          landlord.isCompany = true;
          landlord.businessId = event.businessId;
          landlord.social = null;
        } else {
          landlord.isCompany = false;
          landlord.social = event.social;
          landlord.businessId = null;
        }

        // Check if landlord is partner or account owner
        if (String(this.$store.state.account.currentUser.currentAccount._id) == String(event._id)) {
          landlord.onModel = "Account";
        } else {
          landlord.onModel = "Partner";
        }

        this.contract.landlords.splice(index, 1, landlord);
      }
    },

    updateTenant(event, tenant, index) {
      if (event) {
        tenant.name = event.name;
        tenant.email = event.email;
        tenant.tenantId = event._id;
        delete tenant._id;

        if (event.isCompany) {
          tenant.isCompany = true;
          tenant.businessId = event.businessId;
          tenant.social = null;
        } else {
          tenant.isCompany = false;
          tenant.social = event.social;
          tenant.businessId = null;
        }

        this.contract.otherTenants.splice(index, 1, tenant);
      }
    },

    setTenant(tenant) {
      if (tenant) {
        tenant = { ...tenant, tenantId: tenant._id };
        delete tenant._id;
        this.contract.tenant = tenant;
      }
    },

    setApartment(apartment) {
      if (apartment) {
        apartment = { ...apartment, id: apartment._id };
        delete apartment._id;
        this.contract.apartment = apartment;
      }
    },

    sendContract() {
      if (this.$refs.form.validate()) {
        // Check lease dates
        if (this.contract.lease.leaseType == "Määräaikainen") {
          const startDate = new Date(this.contract.lease.startDate).getTime();
          const endDate = new Date(this.contract.lease.endDate).getTime();

          if (endDate <= startDate) {
            return this.showPopup(
              "Vuokrauksen päättymispäivä tulee olla suurempi kuin alkamispäivä",
              "error"
            );
          }
        }

        let contract = { ...this.contract };

        this.$emit("sendcontract", {
          documentUpdate: contract,
          saveTenantInformation: this.saveTenantInformation,
        });
      } else {
        this.showPopup("Täytä kaikki pakolliset kentät.", "error");
      }
    },

    getIndex(date) {
      if (date) {
        this.setLoadingIndex(true);
        this.getIndexValue({ date, type: this.contract.lease.rentIncreaseType });
      }
    },

    getLatestIndex() {
      this.contract.lease.indexStartDate = "";
      this.contract.lease.indexStartValue = null;

      this.setLoadingIndex(true);
      this.getLatestIndexValue(this.contract.lease.rentIncreaseType);
    },

    editOldContractAfterSigning(canEdit) {
      if (this.contract.signed) {
        if (canEdit) return false;
        else return true;
      } else {
        return false;
      }
    },

    checkFinnishCitizen(val) {
      if (this.contract.tenant.finnishCitizen) {
        return !!val || "Pakollinen kenttä";
      } else {
        return true;
      }
    },

    allowedIndexDates(val) {
      const indexType = this.contract.lease.rentIncreaseType;
      const dateWithoutYear = moment(val).format("MM");

      const allowedQMonths = ["01", "04", "07", "10"];

      if (
        indexType === "propertyMaintenanceIndex" ||
        indexType === "propertyMaintenancePlusPercentageIndex"
      ) {
        if (allowedQMonths.includes(dateWithoutYear)) return true;
        else return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.edit-test,
.v-text-field--outlined fieldset {
  border-color: var(--v-success-base);
}

.details-container {
  display: flex;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
